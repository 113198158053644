


































































































































































































































































import Base from '@/mixins/Base.vue';
import { ISpObject } from '@/interfaces/spobject';
import { IService, IServicesResponse } from '@/interfaces/service';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    props: {
        object: {
            type: Object as () => ISpObject,
            default: null,
        },
    },
    mounted() {
        this.getObjectServices();
    },
    data() {
        return {

            isAddObjectServiceModalActive: false,
            newServices: [] as Array<IService>,
            params: {
                name: '',
                sortBy: 'name',
                orderBy: 'asc',
                page: 1,
            },
            services: {
                data: [] as Array<IService>,
                meta: {} as IPagination | any,
            } as IServicesResponse,
        };
    },
    methods: {

        getObjectServices(page: number = 1): void {
            this.params.page = page;

            this.get<IServicesResponse>(`objects/${this.$route.params.id}/services`, {
                params: this.params,
            }).then(({ data }) => {
                this.services = data;
            });
        },
        addObjectService(): void {
            this.post<IServicesResponse>(`objects/${this.$route.params.id}/services`, {
                service_id: this.newServices.map((p: IService) => p.id),
            }).then(() => {
                this.getObjectServices();
                this.isAddObjectServiceModalActive = false;
            });
        },
        deleteObjectService(service: IService): void {
            this.confirm().then(() => {
                this.delete(`objects/${this.$route.params.id}/services/${service.id}`).then(() => {
                    this.getObjectServices();
                });
            });
        },
    },
});

export default component;
