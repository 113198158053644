



























































































































































































































































































import Base from '@/mixins/Base.vue';
import {
    ISpObject, ISpObjectAttachment, ISpObjectAttachmentsResponse,
} from '@/interfaces/spobject';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    props: {
        object: {
            type: Object as () => ISpObject,
            default: null,
        },
    },
    mounted() {
        this.getObjectAttachments();
    },
    data() {
        return {
            isAddObjectAttachmentsModalActive: false,
            newFiles: [] as Array<any>,
            params: {
                name: '',
                sortBy: 'name',
                orderBy: 'asc',
                page: 1,
            },
            attachments: {
                data: [] as Array<ISpObjectAttachment>,
                meta: {} as IPagination | any,
            } as ISpObjectAttachmentsResponse,
            icons: {
                svg: 'file-image',
                png: 'file-image',
                jpg: 'file-image',
                jpeg: 'file-image',
                pdf: 'file-pdf',
                doc: 'file-word',
                docx: 'file-word',
                xls: 'file-excel',
                xlsx: 'file-excel',

            },
        };
    },
    methods: {
        getObjectAttachments(page: number = 1) {
            this.params.page = page;

            this.get<ISpObjectAttachmentsResponse>(`objects/${this.$route.params.id}/attachments`, {
                params: this.params,
            }).then(({ data }) => {
                this.attachments = data;
            });
        },

        uploadFiles() {
            const form = new FormData();

            Object.values(this.newFiles).forEach((file: any) => {
                form.append('files[]', file);
            });

            this.post<ISpObjectAttachmentsResponse>(`objects/${this.$route.params.id}/attachments`, form).then(() => {
                this.newFiles = [];
                this.getObjectAttachments();
                this.isAddObjectAttachmentsModalActive = false;
            });
        },
        deleteObjectAttachment(attachment: ISpObjectAttachment) {
            this.confirm().then(() => {
                this.delete(`objects/${this.$route.params.id}/attachments/${attachment.id}`).then(() => {
                    this.getObjectAttachments();
                });
            });
        },
    },
});

export default component;
