





























































































































































































































































































































































































import { ISpObject } from '@/interfaces/spobject';
import {
    IInfringement, IInfringementResponse, IInfringementsResponse,
} from '@/interfaces/infringement';
import Base from '@/mixins/Base.vue';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    props: {
        object: {
            type: Object as () => ISpObject,
            default: null,
        },

    },
    mounted() {
        this.getObjectInfringements();
    },
    data() {
        return {
            isAddObjectInfringementModalActive: false,
            params: {
                name: '',
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
            },
            infringements: {
                data: [] as Array<IInfringement>,
                meta: {} as IPagination | any,
            } as IInfringementsResponse,
            newInfringement: {} as (any | IInfringement),
        };
    },
    methods: {
        getObjectInfringements(page: number = 1) {
            this.params.page = page;

            this.get<IInfringementsResponse>(`objects/${this.$route.params.id}/infringements`, {
                params: this.params,
            }).then(({ data }) => {
                this.infringements = data;
            });
        },

        saveObjectInfringement() {
            this.post<IInfringementResponse>(`objects/${this.$route.params.id}/infringements`, this.newInfringement).then(() => {
                this.getObjectInfringements();
                this.isAddObjectInfringementModalActive = false;
            });
        },

        deleteObjectInfringement(infringement: IInfringement) {
            this.confirm().then(() => {
                this.delete(`infringements/${infringement.id}`).then(() => {
                    this.getObjectInfringements();
                });
            });
        },
    },
});

export default component;
