






















































































































































import { ISpObject } from '@/interfaces/spobject';
import {
    IComment, ICommentResponse, ICommentsResponse,
} from '@/interfaces/comment';
import Base from '@/mixins/Base.vue';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    props: {
        object: {
            type: Object as () => ISpObject,
            default: null,
        },

    },
    mounted() {
        this.getObjectComments();
    },
    data() {
        return {
            apiKey: process.env.VUE_APP_TINY_MCE_API_KEY,
            isAddObjectCommentModalActive: false,
            params: {
                name: '',
                sortBy: 'id',
                orderBy: 'desc',
                page: 1,
            },
            comments: {
                data: [] as Array<IComment>,
                meta: {} as IPagination | any,
            } as ICommentsResponse,
            newComment: {} as (any | IComment),
        };
    },
    methods: {
        getObjectComments(page: number = 1) {
            this.params.page = page;

            this.get<ICommentsResponse>(`objects/${this.$route.params.id}/comments`, {
                params: this.params,
            }).then(({ data }) => {
                this.comments = data;
            });
        },

        saveObjectComment() {
            this.post<ICommentResponse>(`objects/${this.$route.params.id}/comments`, this.newComment).then(() => {
                this.getObjectComments();
                this.isAddObjectCommentModalActive = false;
            });
        },

        deleteObjectComment(comment: IComment) {
            this.confirm().then(() => {
                this.delete(`objects/${this.$route.params.id}/comments/${comment.id}`).then(() => {
                    this.getObjectComments();
                });
            });
        },
    },
});

export default component;
