




























































































































































































































































































































































import Base from '@/mixins/Base.vue';

import { ISpObject, ISpObjectResponse } from '@/interfaces/spobject';
import services from './partials/Service.vue';
import attachments from './partials/Attachment.vue';
import infringements from './partials/Infringements.vue';
import comments from './partials/Comments.vue';

const component = Base.extend({
    components: {
        services,
        infringements,
        attachments,
        comments,
    },
    data() {
        return {
            isLoading: false,
            activeTab: 'general',
            isAddCodeModalActive: false,
            object: undefined as (any | ISpObject),

        };
    },
    mounted() {
        this.getObject();
    },
    methods: {

        getObject(): void {
            this.get<ISpObjectResponse>(`objects/${this.$route.params.id}`).then(({ data }) => {
                this.object = data.data;
            });
        },
        saveObject(): void {
            this.put<ISpObjectResponse>(`objects/${this.$route.params.id}`, {
                ...this.object,
                category_id: this.object.category ? this.object.category.id : null,
                country_id: this.object.country ? this.object.country.id : null,
                region_id: this.object.region ? this.object.region.id : null,
            }).then(({ data }) => {
                this.object = data.data;
                this.$emit('success', { message: `object ${data.data.name} updated` });
            });
        },

    },
});

export default component;
