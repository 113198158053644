var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-left"},[_c('h1',{staticClass:"level-item"},[_c('sp-link',{staticClass:"mr-3",attrs:{"tag":"b-button","to":{name:'objects.list'},"icon-left":"arrow-left"}}),( ! _vm.object)?_c('b-skeleton',{attrs:{"size":"is-medium","animated":"","width":"300"}}):[_c('h1',{staticClass:"title"},[_vm._v(" Edit "+_vm._s(_vm.object.name)+" "),(_vm.object.client)?_c('sp-link',{attrs:{"to":{name:'objects.edit',params:{id: _vm.object.client.id}}}},[_c('b-tag',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.object.client.name)+" ")])],1):_vm._e()],1)]],2)])]),(_vm.object)?_c('b-tabs',{staticClass:"block",attrs:{"position":"is-centered","expanded":"","destroy-on-hide":""},on:{"input":function($event){_vm.activeTab = $event}}},[_c('b-tab-item',{attrs:{"label":"General","value":"general","icon":"info-circle"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveObject)}}},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" General ")]),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"name","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"ID Label","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.object.name),callback:function ($$v) {_vm.$set(_vm.object, "name", $$v)},expression:"object.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"icon","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Icon","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-icon',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.object.icon),callback:function ($$v) {_vm.$set(_vm.object, "icon", $$v)},expression:"object.icon"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"color","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"color","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-color',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.object.color),callback:function ($$v) {_vm.$set(_vm.object, "color", $$v)},expression:"object.color"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"label","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Label","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.object.label),callback:function ($$v) {_vm.$set(_vm.object, "label", $$v)},expression:"object.label"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"client_reference_1","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Client Reference 1","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.object.client_reference_1),callback:function ($$v) {_vm.$set(_vm.object, "client_reference_1", $$v)},expression:"object.client_reference_1"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"client_reference_2","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Client Reference 2","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.object.client_reference_2),callback:function ($$v) {_vm.$set(_vm.object, "client_reference_2", $$v)},expression:"object.client_reference_2"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"description","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Description","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"type":"textarea","disabled":_vm.isLoading},model:{value:(_vm.object.description),callback:function ($$v) {_vm.$set(_vm.object, "description", $$v)},expression:"object.description"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"serial","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Serial","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-input',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.object.serial),callback:function ($$v) {_vm.$set(_vm.object, "serial", $$v)},expression:"object.serial"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"category","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Category","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-object-category',{attrs:{"disabled":_vm.isLoading,"client-id":_vm.object.client_id,"expanded":""},model:{value:(_vm.object.category),callback:function ($$v) {_vm.$set(_vm.object, "category", $$v)},expression:"object.category"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"immediate":"","rules":"required","name":"active","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Status","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('b-switch',{attrs:{"disabled":_vm.isLoading},model:{value:(_vm.object.active),callback:function ($$v) {_vm.$set(_vm.object, "active", $$v)},expression:"object.active"}},[_vm._v(" Active ")])],1)]}}],null,true)})],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" Localisation ")]),_c('b-field',{attrs:{"horizontal":"","label":"Address"}},[_c('sp-address-form',{attrs:{"disabled":_vm.isLoading,"error":_vm.errors,"with-map":true,"with-autocomplete":true,"with-current-position":true},model:{value:(_vm.object.address),callback:function ($$v) {_vm.$set(_vm.object, "address", $$v)},expression:"object.address"}})],1)],1),_c('div',{staticClass:"block"},[_c('div',{staticClass:"subtitle"},[_vm._v(" QR Code ")]),_c('b-field',{attrs:{"horizontal":"","label":"QR code"}},[_c('div',{staticClass:"media"},[(_vm.object.qrcode)?_c('div',{staticClass:"media-left"},[_c('qrcode-vue',{attrs:{"value":_vm.object.qrcode,"size":200}})],1):_vm._e(),_c('div',{staticClass:"media-content"},[_c('b-button',{attrs:{"disabled":_vm.isLoading},on:{"click":function($event){_vm.isAddCodeModalActive = true}}},[_vm._v(" Add QR Code ")])],1)])])],1),_c('b-field',{attrs:{"horizontal":""}},[_c('b-button',{attrs:{"type":"is-primary","icon-left":"save","disabled":! valid || _vm.isLoading},on:{"click":function($event){return _vm.saveObject()}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}],null,false,149564301)})],1)],1):_vm._e(),_c('b-modal',{model:{value:(_vm.isAddCodeModalActive),callback:function ($$v) {_vm.isAddCodeModalActive=$$v},expression:"isAddCodeModalActive"}},[_c('form',{staticClass:"modal-card"},[_c('div',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"immediate":"","rules":"","name":"qrcode","tag":"b-field"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Scan","type":{'is-danger': errors[0]},"message":errors ? errors[0] : null}},[_c('sp-webcam',{on:{"input":function($event){_vm.object.qrcode = $event; _vm.isAddCodeModalActive = false}}})],1)]}}])})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"disabled":_vm.isLoading,"label":_vm.$t('close'),"icon-left":"times"},on:{"click":function($event){_vm.isAddCodeModalActive = false}}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }